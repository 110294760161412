import { useState, useEffect, useMemo } from "react";

import axios from "axios";
import { ethers } from "ethers";

const provider = new ethers.providers.JsonRpcProvider(
  "https://api.avax.network/ext/bc/C/rpc"
);



export const useTokenPrice = (tokenAddress) => {
  const [price, setPrice] = useState(null);
  const [liquidityUsd, setLiquidityUsd] = useState(null);
  const [errorForPrice, setError] = useState(null);
  const [loadingForPrice, setLoading] = useState(true);

  async function fetchTokenPrice() {
    const url = `https://api.dexscreener.com/latest/dex/tokens/${tokenAddress}`;

    try {
      setLoading(true);
      const response = await axios.get(url);

      if (response.data && response.data.pairs && response.data.pairs.length > 0) {
        const pair = response.data.pairs[0];
        setPrice(pair.priceUsd);
        const formattedliquidity = parseFloat(pair.liquidity.usd).toLocaleString(
          "en-US",
          { style: "currency", currency: "USD" }
        );
        setLiquidityUsd(formattedliquidity);
      } else {
        throw new Error("Token data not found or no pairs available");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchTokenPrice();
    const intervalId = setInterval(fetchTokenPrice, 60000); // Refresh every 1 minute

    return () => clearInterval(intervalId);
  }, [tokenAddress]);

  return { price, liquidityUsd, errorForPrice, loadingForPrice };
};


export const useMarketCap = (tokenId) => {
  const [marketCap, setMarketCap] = useState(null);
  const [errorForMc, setError] = useState(null);
  const [loadingForMc, setLoading] = useState(true);

  async function fetchMarketCap() {
    const url = `https://api.dexscreener.com/latest/dex/tokens/${tokenId}`;
    try {
      setLoading(true);
      const response = await axios.get(url);
      if (response.data && response.data.pairs && response.data.pairs.length > 0) {
        const marketCapData = response.data.pairs[0].fdv; 
        const formattedmarketCapData = parseFloat(marketCapData).toLocaleString(
          "en-US",
          { style: "currency", currency: "USD" }
        );
        setMarketCap(formattedmarketCapData);
      } else {
        throw new Error("Market cap data not found for the token");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchMarketCap();
    const intervalId = setInterval(fetchMarketCap, 60000); // Refresh every 1 minute

    return () => clearInterval(intervalId);
  }, [tokenId]);

  return { marketCap, errorForMc, loadingForMc };
};


export const useTotalHolders = (tokenId, tokenContractAbi) => {
  const [totalHolders, setTotalHolders] = useState(null);
  const [errorforholder, setError] = useState(null);
  const [loadingfoholder, setLoading] = useState(true);

  const tokenContract = useMemo(
    () => new ethers.Contract(tokenId, tokenContractAbi, provider),
    [tokenId, tokenContractAbi, provider]
  );

  useEffect(() => {
    const fetchTotalHolders = async () => {
      try {
        setLoading(true);
        const holders = await tokenContract.getNumberOfDividendTokenHolders();

        setTotalHolders(holders.toNumber());
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTotalHolders();
    const intervalId = setInterval(fetchTotalHolders, 60000); // 60000 ms = 1 minute

    return () => {
      clearInterval(intervalId);
    };
  }, [tokenContract]);

  return { totalHolders, errorforholder, loadingfoholder };
};

export const useClaimRewards = (tokenId, tokenContractAbi) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [transaction, setTransaction] = useState(null);

  const claimRewards = async () => {
    try {
      setLoading(true);

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();

      const contract = new ethers.Contract(tokenId, tokenContractAbi, signer);

      const tx = await contract.claim();

      setTransaction(tx);
    } catch (err) {
      setError(err.message || err.toString());
    } finally {
      setLoading(false);
    }
  };

  return { claimRewards, loading, error, transaction };
};

export const usePendingRewards = (
  currentAccount,
  tokenId,
  tokenContractAbi,
  rewardsToken
) => {
  const [pendingRewards, setPendingRewards] = useState(0);
  const [loadingforrewards, setLoading] = useState(true);

  const contract = useMemo(() => {
    try {
      return new ethers.Contract(tokenId, tokenContractAbi, provider);
    } catch (error) {
      console.error("Failed to create contract instance:", error);
      return null;
    }
  }, [tokenId, tokenContractAbi]);

  useEffect(() => {
    const fetchPendingRewards = async () => {
      try {
        if (!contract) throw Error("Contract instance not available.");

        const rewards = await contract.withdrawableDividendOf(
          currentAccount,
          rewardsToken
        );
        setPendingRewards(ethers.utils.formatEther(rewards)); // Convertir en Ether, si vos récompenses sont en Wei
      } catch (error) {
        console.error("Error", error);
        setPendingRewards("Error");
      } finally {
        setLoading(false);
      }
    };

    if (ethers.utils.isAddress(currentAccount)) {
      fetchPendingRewards();
    }
    const intervalId = setInterval(fetchPendingRewards, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentAccount, contract]);

  return { pendingRewards, loadingforrewards };
};

export const useTotalDividends = (tokenId, tokenContractAbi, rewardsToken) => {
  const [totalDividends, setTotalDividends] = useState(0);
  const [loadingforDiv, setLoading] = useState(true);
  const [errorforDiv, setError] = useState(null);

  const contract = useMemo(() => {
    try {
      return new ethers.Contract(tokenId, tokenContractAbi, provider);
    } catch (error) {
      console.error("Failed to create contract instance:", error);
      return null;
    }
  }, [tokenId, tokenContractAbi]);

  useEffect(() => {
    const fetchTotalDividends = async () => {
      try {
        const dividends = await contract.getTotalDividendsDistributed(
          rewardsToken
        );
        const Totaldiv = ethers.utils.formatUnits(dividends, 18);
        const truncatedTotalDiv = Totaldiv.split(".")[0];
        setTotalDividends(truncatedTotalDiv);

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchTotalDividends();
    const intervalId = setInterval(fetchTotalDividends, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [tokenId, tokenContractAbi, rewardsToken]);

  return { totalDividends, loadingforDiv, errorforDiv };
};
