import { ethers } from "ethers";
import React, { useState, createContext, useEffect } from 'react';
import * as Wallet from './Wallet'; // Make sure this path is correct
const Alert = require("sweetalert2");

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [currentAccount, setCurrentAccount] = useState();
    const [networkProvider, setNetworkProvider] = useState();

    // Define Goerli network parameters
    const GOERLI_NETWORK_ID = 43114;
    const GOERLI_NETWORK_NAME = "Avalanche C-Chain";
    const GOERLI_RPC_URL = "https://api.avax.network/ext/bc/C/rpc"; // Replace with your Infura API key

    const ourProvider = async () => {
        const provider = await Wallet.provider();
        setNetworkProvider(provider);
    }

    const connectWallet = async () => {
        setLoading(true);
        try {
            await ourProvider();
            const account = await Wallet.connect();
            setCurrentAccount(account[0]);
            await verifyNetwork(); // Automatically verify the network upon connecting
        } catch (error) {
            console.error("Could not connect to wallet", error);
        } finally {
            setLoading(false);
        }
    }

    const disconnectWallet = async () => {
        await Wallet.disconnect();
        setCurrentAccount(undefined); // Clear the current account state
    }

    const verifyNetwork = async () => {
        try {
            // Attempt to switch to the Goerli network
            await Wallet.verifyNetwork(GOERLI_NETWORK_ID, GOERLI_NETWORK_NAME, GOERLI_RPC_URL);
        } catch (error) {
            console.error("Could not verify network", error);
        }
    }

    useEffect(() => {
        if (Wallet.web3Modal.cachedProvider) {
            connectWallet();
        }
    }, []);

    useEffect(() => {
        if (networkProvider?.on) {
            const handleAccountsChanged = (accounts) => {
                if (accounts.length > 0) {
                    setCurrentAccount(accounts[0]);
                }
            };

            const handleChainChanged = async (_hexChainId) => {
                const chainId = ethers.BigNumber.from(_hexChainId).toNumber();
                if (chainId !== GOERLI_NETWORK_ID) {
                    await verifyNetwork();
                }
            };

            const handleDisconnect = () => {
                disconnectWallet();
            }

            networkProvider.on("accountsChanged", handleAccountsChanged);
            networkProvider.on("chainChanged", handleChainChanged);
            networkProvider.on("disconnect", handleDisconnect);

            return () => {
                if (networkProvider.removeListener) {
                    networkProvider.removeListener("accountsChanged", handleAccountsChanged);
                    networkProvider.removeListener("chainChanged", handleChainChanged);
                    networkProvider.removeListener("disconnect", handleDisconnect);
                }
            };
        }
    }, [networkProvider]);

    return (
        <AppContext.Provider value={{ connectWallet, disconnectWallet, currentAccount, verifyNetwork, loading }}>
            {children}
        </AppContext.Provider>
    );
};
