import React, { useContext } from "react";
import { FaConnectdevelop } from "react-icons/fa";
import { AppContext } from "../context/AppContext";
import { shortenAddress } from "../utils/ShortAdress";
import nyanCatGif from "../assets/nyancat.gif";
import {
  useTokenPrice,
  useMarketCap,
  useTotalHolders,
  useClaimRewards,
  usePendingRewards,
  useTotalDividends
} from "./../utils/hook";

import tokenContractAbi from "./../utils/abis/token-abi.json";

const Welcome = () => {
  const rewardsToken = "0x184ff13B3EBCB25Be44e860163A5D8391Dd568c1";
  const tokenId = "0x2ED094fAea4089078DCDD54c5B9FdBD6cb40BA74";
  const { currentAccount, connectWallet } = useContext(AppContext);
  const {transactionHash, error, loading, claimRewards } =
    useClaimRewards(tokenId, tokenContractAbi);

    const { pendingRewards, loadingforrewards } = usePendingRewards(
      currentAccount, tokenId, tokenContractAbi, rewardsToken
    );
    const {  totalDividends, loadingforDiv, errorforDiv  } = useTotalDividends(
      tokenId, tokenContractAbi, rewardsToken
    );


  const { price,liquidityUsd, errorforprice, loadingforprice } = useTokenPrice(
    tokenId
  );
  const { marketCap, errorformc, loadingformc } = useMarketCap(
    tokenId,
  );
  const { totalHolders, errorforholder, loadingfoholder } = useTotalHolders(
    tokenId,
    tokenContractAbi,
    price
  );

  

  //console.log("price",price)
  return (
    <div className="welcome flex w-full m-auto justify-center items-start p-5 pt-5">
      <div className="welcome-div-text md:flex-1 flex justify-center px-2 flex-wrap items-center self-center">
        <div className="w-full text-white text-center text-2xl sm:text-3xl md:text-4xl py-3 font-bold">
          Global KimboSlice Stats
        </div>
        <div className="dashboard-row grid sm:grid-cols-1 md:grid-cols-3 gap-4 mb-4 w-full mx-auto">
          <div className="dashboard-item p-2 bg-gray-800 border border-gray-700 rounded-lg text-white text-base md:text-lg shadow-md hover:shadow-xl transition-shadow duration-300 flex flex-col items-center justify-center space-y-2">
            <i className="fas fa-dollar-sign"></i>
            <span className="font-bold">Price</span>
            {loadingforprice ? (
              <span>Loading...</span>
            ) : errorforprice ? (
              <span>Error: {errorforprice}</span>
            ) : (
              <span>${price}</span>
            )}
          </div>
          <div className="dashboard-item p-2 bg-gray-800 border border-gray-700 rounded-lg text-white text-base md:text-lg shadow-md hover:shadow-xl transition-shadow duration-300 flex flex-col items-center justify-center space-y-2">
            <i className="fas fa-chart-line"></i>
            <span className="font-bold">Marketcap</span>
            {loadingformc ? (
              <span>Loading...</span>
            ) : errorformc ? (
              <span>Error: {errorformc}</span>
            ) : (
              <span>{marketCap}</span>
            )}
          </div>

          <div className="dashboard-item p-2 bg-gray-800 border border-gray-700 rounded-lg text-white text-base md:text-lg shadow-md hover:shadow-xl transition-shadow duration-300 flex flex-col items-center justify-center space-y-2">
            <i className="fas fa-coins"></i>
            <span className="font-bold">Total Supply</span>
            <span>
            {loadingformc ? (
              <span>Loading...</span>
            ) : errorformc ? (
              <span>Error: {errorformc}</span>
            ) : (
              <span>69,420,000,000 $SKIMBO</span>
            )}
            </span>
          </div>
        </div>
        <div className="dashboard-row grid sm:grid-cols-1 md:grid-cols-3 gap-4 mb-4 w-full mx-auto">
          <div className="dashboard-item p-2 bg-gray-800 border border-gray-700 rounded-lg text-white text-base md:text-lg shadow-md hover:shadow-xl transition-shadow duration-300 flex flex-col items-center justify-center space-y-2">
            <i className="fas fa-lock"></i>
            <span className="font-bold">Total distributed reward</span>
            {loadingforDiv ? (
              <span>Loading...</span>
            ) : errorforDiv ? (
              <span>Error: {errorforprice}</span>
            ) : (
              <span>${totalDividends}</span>
            )}
          </div>
          <div className="dashboard-item p-2 bg-gray-800 border border-gray-700 rounded-lg text-white text-base md:text-lg shadow-md hover:shadow-xl transition-shadow duration-300 flex flex-col items-center justify-center space-y-2">
            <i className="fas fa-fire"></i>
            <span className="font-bold">Liquidity Values</span>
            {loadingforprice ? (
              <span>Loading...</span>
            ) : errorforprice ? (
              <span>Error: {errorforprice}</span>
            ) : (
              <span>{liquidityUsd}</span>
            )}
           
          </div>
          <div className="dashboard-item p-2 bg-gray-800 border border-gray-700 rounded-lg text-white text-base md:text-lg shadow-md hover:shadow-xl transition-shadow duration-300 flex flex-col items-center justify-center space-y-2">
            <i className="fas fa-question-circle"></i>
            <span className="font-bold">Holders</span>
            {loadingfoholder ? (
              <span>Loading...</span>
            ) : errorforprice ? (
              <span>Error: {errorforholder}</span>
            ) : (
              <span>{totalHolders}</span>
            )}
          </div>
        </div>
        {/* Anti-Jeet Section */}
        <div className="anti-jeet-section mt-16 w-full lg:max-w-6xl mx-auto relative">
          <img src={nyanCatGif} alt="NyanCat" className="nyancat-slide" />
          <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-6">
            <div className="rewards-block md:col-span-3 p-8 border rounded-lg text-white shadow-md hover:shadow-lg transition-shadow duration-300 flex flex-col justify-center items-center">
              <div className="rewards-title text-2xl font-bold mb-6 text-center">
                Kimbo Rewards
              </div>
              <div className="rewards-description text-xl mb-6">
              {loadingforrewards ? (
              <span>Loading...</span>
            ) : (
              <span>Your Pending Rewards: {pendingRewards}</span>
            )}
              </div>
              <button
  className="claim-btn px-8 py-4 rounded-full"
  onClick={claimRewards}
  disabled={!loading && pendingRewards <= 0}
>
  {loading ? "Claiming..." : pendingRewards > 0 ? "Claim Kimbo" : "No Rewards to Claim"}
</button>
            </div>
          </div>
        </div>

        {/* Buy Section */}
        <div className="chart-section mt-20 w-full mx-auto">
          <div className="text-white text-center text-2xl sm:text-3xl md:text-4xl py-3 font-bold">
          KimboSlice Chart
          </div>

          {/* Uniswap Embed */}
          <iframe
            src="https://dexscreener.com/avalanche/0x2ED094fAea4089078DCDD54c5B9FdBD6cb40BA74
            ?embed=1&theme=dark&trades=0&info=0"
            height="620px"
            width="100%"
            style={{
              border: "0",
              margin: "0 auto",
              marginBottom: ".5rem",
              display: "block",
              borderRadius: "2px",
              maxWidth: "960px",
              minWidth: "300px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Welcome);
